import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { authStore } from "../store/AuthStore";
import { rewardsStore } from "../store/RewardsStore";
import { Box, Grid, Heading, Skeleton, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import { RewardItem } from "../components/RewardItem";
import { Page } from "../components/Page";

export const Rewards: React.FC = observer(() => {
  const user = authStore.user;
  const toast = useToast();
  useEffect(() => {
    if (user?.id) {
      rewardsStore.getRewards(user.id);
      authStore.reFetchUser(user.id);
    } else {
      authStore.getUser();
    }
  }, [user?.id])
  const rewards = rewardsStore.rewards;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');

  return (
    <Page>
      <Grid gap="1em">
        <Box display="grid" bg="brand.700" alignItems="center" h="65px">
          <Text fontSize="20px" textAlign="center">You have <b>{user?.spendable} points</b> to Spend</Text>
        </Box>
        <Grid placeContent="center" color="brand.500">
          <Heading textAlign="center" size="sm">WHAT YOU'LL GET</Heading>
          <Heading size="xl" borderBottom="1px solid" borderColor="brand.300">REWARDS</Heading>
        </Grid>
        <Grid overflow="auto" templateColumns={isDesktop ? 'auto auto auto' : '1fr'} minH="500px" pt="1em" gap="1em">
          { rewardsStore.isLoading && 
            <Grid h="150px" p="1em" gap="0.7em" borderRadius="4px" overflow="hidden" m="0 1em">
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Grid>
          }
          {
            !rewardsStore.isLoading && rewards.map((r) => {
              const hasEnoughPoints = r.points <= (user?.spendable || 0);
              return (
                <RewardItem
                  key={r.id}
                  reward={r}
                  hasEnoughPoints={hasEnoughPoints}
                  spendable={user?.spendable || 0}
                  onClaim={async () => {
                    if (user?.id) {
                      const claimResponse = await rewardsStore.claimReward(user.id, r.id);
                      await authStore.reFetchUser(user.id);
                      if (claimResponse.error) {
                        toast({ title: claimResponse.error.message, status: 'error' })
                      } else { 
                        toast({ status: 'success', title: `Successfully spent ${claimResponse.spent} points!` });
                      }
                    }
                  }}
                />
              )
            })
          }
        </Grid>
      </Grid>
    </Page>
  )
})