import { Grid, Text, Box, Image, Progress, Button, ProgressLabel, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { format } from "date-fns";
import { Challenge } from "../store/ChallengesStore";

type ChallengeCardProps = {
  challenge: Challenge;
}

export const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge }) => {
  const totalCompleted = challenge.progress.criteria.reduce((acc, c) => acc += c.current, 0);
  const totalCriteria = challenge.progress.criteria.reduce((acc, c) => acc += c.threshold, 0);
  const percentage = totalCompleted > 0 ? 100 * totalCompleted / totalCriteria : 0;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  return (
    <Grid
      position="relative"
      borderRadius="10px"
      overflow="hidden"
      w={isDesktop ? '450px' : undefined}
    >
      <Box overflow="hidden" maxH={isDesktop ? '200px' : undefined}>
        <Image w="100%" src={challenge.image.medium} />
      </Box>
      <Progress bgColor="brand.200" h="22px" value={percentage}>
        <ProgressLabel color="brand.500">{totalCompleted} / {totalCriteria} Completed!</ProgressLabel>
      </Progress>
      <Grid>
        {challenge.ends_at && <Text fontStyle="italic" alignSelf="end" color="brand.100">Until {format(new Date(challenge.ends_at), 'MMM eo')}</Text>}
        <Grid alignItems="center" placeContent="center" templateColumns="auto auto" gap="0.5em">
          <Text textAlign="center" fontWeight="bold" color="brand.500" fontSize="16px" noOfLines={1} textOverflow="ellipsis">{challenge.title.toUpperCase()}</Text>
          <Text fontWeight="bold" color="brand.700" fontSize="16px">+{challenge.points} POINTS</Text>
        </Grid>
        <Text textAlign="center">{challenge.description}</Text>
        {challenge.cta_label && <Button mt="1em" p="0.6em 3em" placeSelf="center" fontSize="14px" variant="mainSolid">{challenge.cta_label.toUpperCase()}</Button>}
      </Grid>
    </Grid>
  )
}