import { makeAutoObservable } from 'mobx';
import { AntavoImage } from './shared/Image';

type ChallengeProgressCriteria = {
  action: string;
  message: string;
  threshold: number;
  current: number;
  is_completed: boolean;
}

type ChallengeProgress = {
  overall: number;
  criteria: ChallengeProgressCriteria[];
}

export interface Challenge {
  id: string;
  title: string;
  description: string;
  image: AntavoImage;
  points: number;
  progress: ChallengeProgress;
  url: string;
  cta_label: string;
  completions: number;
  ends_at: string;
  starts_at: string;
}

class ChallengesStore {
  challenges: Challenge[] = [];
  isLoading: boolean = false;
  rewardApiUrl?: string = process.env.REACT_APP_REWARD_API_URL
  constructor() {
    makeAutoObservable(this);
  }

  async getChallenges(customerId: string) {
    this.isLoading = true;
    if (!this.rewardApiUrl) {
      console.error('process.env.REACT_APP_REWARD_API_URL is not defined and it is needed by the challenge store');
      return;
    }
    const res = await fetch(`${this.rewardApiUrl}/${customerId}/challenges`,  { headers: { 'Accept-Language': 'en' }});
    const json = await res.json();
    if (json.data) {
      this.challenges = json.data;
    }
    this.isLoading = false;
    return this.challenges;
  }
  async getAllEntityChallenges() {
    this.isLoading = true;
    const res = await fetch(
      'https://api.salesdemo.antavo.com/entities/challenges/challenge?api_key=AN2OF2LPTAAFGDVOCOH',
      { headers: { 'Accept-Language': 'en' }}
    )
    if (res.ok) {
      const json = await res.json();
      return json.data
    }
  }
}

export const challengesStore = new ChallengesStore();