import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react'

const ButtonStyle: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    pt: '0.5em',
    pb: '0.5em'
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    solid: {
      bg: 'brand.300',
      color: 'brand.100',
      p: '0.5em',
      _hover: {
        bg: 'brand.500',
      },
      _focus: {
        bg: 'brand.500',
      }
    },
    solidSecondary: {
      bg: 'brand.700',
      color: 'brand.500',
      p: '0.5em',
      _disabled: {
        bg: 'brand.200',
        color: 'brand.100',
        opacity: 0.5
      }
    },
    mainSolid: {
      bg: 'brand.500',
      color: 'brand.100',
      p: '0.5em',
      _hover: {
        bg: 'brand.500',
      },
      _focus: {
        bg: 'brand.500',
      }
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: '',
    variant: 'solid',
    colorScheme: 'brand',
  },
}

const LinkStyle: ComponentStyleConfig = {
  defaultProps: {
    variant: 'brand'
  },
  variants: {
    brand: {
      color: 'brand.500'
    },
    white: {
      color: 'brand.100'
    }
  }
}

const ProgressStyle: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'brand2',
    borderRadius: '1em'
  },
}

export const theme = extendTheme({
  fonts: {
    heading: 'Libre Franklin", sans-serif',
    body: 'Libre Franklin", sans-serif'
  },
  colors: {
    brand: {
      100: '#FFFFFF',
      200: '#eff3f6',
      300: '#5b8db6',
      500: '#323f48',
      600: '#E6E7E8',
      700: '#e4ba55',
      800: '#D50000',
    },
    brand2: {
      500: '#e4ba55',
    }
  },
  components: {
    Button: ButtonStyle,
    Link: LinkStyle,
    Progress: ProgressStyle,
  }
});